const RadiologyExaminationContent = ({
  patientName,
  ownerName,
  clinicName,
  patientID,
  gender,
  birthDate,
  species,
  breed,
  weight,
  img,
  studyDate,
}) => {
  return `  <h4 style="text-align: center">Radiology Examination</h4><table>
    <tbody>
      <tr>
        <th><strong>Referred By</strong></th>
        <th><strong>Patient Details</strong></th>
      </tr>
      <tr>
        <td>Name:</td>
        <td>Patient ID: <b>${patientID}</b></td>
      </tr>
      <tr>
        <td>Clinic:</td>
        <td>Patient Name: <b>${patientName}</b></td>
      </tr>
      <tr>
        <td>Address:</td>
        <td>Age: </td>
      </tr>
      <tr>
        <td>Phone:</td>
        <td>Gender: <b>${gender}</b></td>
      </tr>
      <tr>
        <td>Email:</td>
        <td>Birthdate : <b>${birthDate}</b></td>
      </tr>
      <tr>
        <td>Study Date: <b>${studyDate}</b></td>
        <td>Height:</td>
      </tr>
      <tr>
        <td>Report Date:<b></b></td>
        <td>Weight:</td>
      </tr>
    </tbody>
    </table>
    <p></p>
  <h5><u>Radiology Examination Findings:</u></h5><p></p>
    <h5><u>General Findings and Remarks:</u></h5><p></p>
  <h5><u>Comments</u></h5><p></p><p></p>
  <h5><u>Preview</u></h5>
  ${img}</p><h5><u>Notes</u></h5><p></p>
  
  `;
};

export default RadiologyExaminationContent;
