import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import MailButton from "./buttons/MailButton";
import DeleteStudy from "./buttons/DeleteStudy";
import DeleteSeries from "./buttons/DeleteSeries";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StudyLink from "./buttons/StudyLink";
import DicomSend from "./buttons/DicomSend";
import UploadReport from "./buttons/UploadReport";
import DownloadDialogButton from "./buttons/DownloadDialogButton";
import Tooltip from "@mui/material/Tooltip";
import CreateReport from "./buttons/CreateReport";
import { Divider, Modal } from "@mui/material";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import ModalWindow from "./templates/ModalWindow";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

function Row(props) {
  const { row } = props;

  const [instanceId, setInstanceId] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  const [isLoadingRow, setIsLoadingRow] = useState(true);
  const [isReport, setIsReport] = useState(false);
  const [contentType, setContentType] = useState("");
  const [contentType1, setContentType1] = useState("");
  const [content, setContent] = useState("");

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);

  const handleRedirect = async (studyUID) => {
    window.open(`/pacs/viewer/${studyUID}?s=${props.token}`);
  };

  const handleRowClick = async (id) => {
    setInstanceId(id);
  };

  const getInstance = async (id) => {
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studyid.php`;
    var config = {
      method: "post",
      url: session_url,
      data: {
        sid: props.token,
        studyid: id,
      },
    };
    axios.defaults.withCredentials = true;
    const res = await axios(config);
    var url = Object.keys(res.data).map(function (key) {
      var url = res.data[key];
      return url;
    });
    setImageUrl(url);
    setIsLoadingRow(false);

    const reportUrl = `${process.env.REACT_APP_BACKEND_PHP_URL}/getReportList.php`;
    var config1 = {
      method: "post",
      url: reportUrl,
      data: {
        sid: props.token,
        studyId: id,
      },
    };
    axios.defaults.withCredentials = true;
    const res1 = await axios(config1);
    setIsLoadingRow(false);
    if (!res1.data.status) {
      setIsReport(res1.data[0].status);
      setContentType(res1.data.map((item) => item.type));
      return; // Stop executing further code
    }
  };
  const getReport = async (type, id) => {
    setIsLoadingRow(true);
    const reportUrl = `${process.env.REACT_APP_BACKEND_PHP_URL}/getReport.php`;
    var config1 = {
      method: "post",
      url: reportUrl,
      data: {
        sid: props.token,
        studyId: `${id}/${type}`,
      },
    };
    axios.defaults.withCredentials = true;
    const res1 = await axios(config1);
    setContent(res1.data.draft_json);
    setIsLoadingRow(false);
  };
  const imageTags = imageUrl
    .filter((url) => url.description !== "PDF Document")
    .map(
      (url, i) =>
        `<div 
      key="${i}" 
      style="display: flex; justify-content: center; align-items: center; width: 160px; height: 160px; background-color: black; padding-top: 3px; padding-right: 3px; cursor: pointer;"
    >
      <img src="${url.thumb}" alt="${url.description}" style="max-width: 100%; max-height: 100%;" />
    </div>`
    );

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "none", padding: 10 },
          "& td": { border: 0 },
        }}
        onClick={() => {
          handleRowClick(row.ID);
          getInstance(row.ID);
          props.onClick(props.open ? "" : row.ID);
        }}
        hover
      >
        <TableCell style={{ paddingBottom: 10, paddingTop: 10 }}>
          <IconButton aria-label="expand row" size="small">
            {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.PatientMainDicomTags.PatientID}</TableCell>
        <TableCell align="left">
          {row.PatientMainDicomTags.PatientName}
        </TableCell>
        <TableCell align="left">
          {row.RequestedTags.ModalitiesInStudy}
        </TableCell>
        <TableCell align="left">{row.MainDicomTags.InstitutionName}</TableCell>
        <TableCell align="left">
          {row.MainDicomTags.StudyDate.substring(6, 8) +
            "-" +
            row.MainDicomTags.StudyDate.substring(4, 6) +
            "-" +
            row.MainDicomTags.StudyDate.substring(0, 4) +
            ", " +
            row.MainDicomTags.StudyTime.substring(0, 2) +
            ":" +
            row.MainDicomTags.StudyTime.substring(2, 4)}
        </TableCell>
        <TableCell align="left">{row.MainDicomTags.StudyDescription}</TableCell>
        <TableCell align="left">{row.MainDicomTags.AccessionNumber}</TableCell>
        <TableCell align="left">{row.Series.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={10}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          sx={{ borderColor: "#585F7E" }}
        >
          <Collapse in={props.open} timeout="auto" unmountOnExit>
            <Box m={3}>
              <Stack direction="row" spacing={1.5}>
                <DownloadDialogButton id={row.ID} token={props.token} />
                <DeleteStudy
                  id={row.ID}
                  func={props.func}
                  token={props.token}
                />
                <DeleteSeries
                  id={row.ID}
                  func={props.func}
                  token={props.token}
                  text={"delete series"}
                  func1={() => getInstance(row.ID)}
                />

                <MailButton id={row.ID} token={props.token} />
                <StudyLink
                  id={row.ID}
                  token={props.token}
                  text={"Study Link"}
                />
                <DicomSend
                  id={row.ID}
                  token={props.token}
                  text={"dicom send"}
                />
                <UploadReport
                  id={row.ID}
                  token={props.token}
                  func1={() => getInstance(row.ID)}
                  func={props.func}
                />
                <CreateReport
                  details={row}
                  token={props.token}
                  func1={() => getInstance(row.ID)}
                  func={props.func}
                  id={row.ID}
                  img={imageTags}
                  existing={contentType}
                />
              </Stack>

              {isLoadingRow ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  <Stack direction="row">
                    {imageUrl.map((url, i) => (
                      <Box
                        onClick={() =>
                          handleRedirect(row.MainDicomTags.StudyInstanceUID)
                        }
                        key={i}
                        alignSelf="strech"
                        sx={{
                          paddingTop: 3,
                          cursor: "pointer",
                          paddingRight: 3,
                          "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          // minHeight="100vh"
                          sx={{
                            width: 160,
                            height: 160,
                            backgroundColor: "black",
                          }}
                        >
                          <img src={url.thumb} alt={url.description} />
                        </Box>
                        <Typography gutterBottom variant="body2">
                          {url.description}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                  {isReport === "true" && contentType.length > 0 ? (
                    <>
                      <Divider />
                      <Stack direction="column" spacing={2}>
                        {contentType.map((type, index) => (
                          <Button
                            key={index}
                            variant="contained"
                            endIcon={<ModeEditOutlineIcon />}
                            onClick={() => {
                              setOpenFullScreenDialog(true);
                              getReport(type, row.ID);
                              setContentType1(type);
                            }}
                            sx={{
                              width: "180px",
                              background: "#80848c",
                              fontSize: "11px",
                              ":hover": {
                                bgcolor: "#0290A2",
                                color: "black",
                              },
                            }}
                          >
                            {type === "radiologyReport"
                              ? "Radiology Report"
                              : type === "radiologyExamination"
                              ? "Radiology Examination"
                              : type}
                          </Button>
                        ))}
                        {openFullScreenDialog && (
                          <ModalWindow
                            open={openFullScreenDialog}
                            setOpen={setOpenFullScreenDialog}
                            templateName={contentType1}
                            data={props}
                            content={content}
                            func1={() => getInstance(row.ID)}
                          />
                        )}
                      </Stack>
                    </>
                  ) : null}
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Studies(props) {
  //file_upload
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  //
  const [patientId, setPatientId] = useState("");
  const [patientName, setPatientName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [modality, setModality] = useState(""); //modality not in dicom tags
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [modalities, setModalities] = useState([]);
  // const [token, setToken] = useState("");

  const [studyData, setStudyData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [openRow, setOpenRow] = useState("");

  const handleChangeRows = (event) => {
    setLimit(event.target.value);
  };

  useEffect(() => {
    if (isMounted) {
      fetchModalities()
        .then((data) => setModalities(data))
        .catch((error) => console.error("Error fetching modalities:", error));
      getRes(props.token);
    }
  }, [
    rowsPerPage,
    modality,
    fromDate,
    toDate,
    DeleteStudy,
    page,
    limit,
    offset,
    props.trigger,
  ]);

  useEffect(() => {
    return () => {
      setStudyData(null);
      setIsMounted(false);
    };
  }, []);

  const previousPage = () => {
    setOffset(offset - limit);
  };
  const nextPage = () => {
    setOffset(offset + limit);
  };

  const fetchModalities = () => {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_PHP_URL}/modalityNamePair.php`)
      .then((response) => response.data)
      .catch((error) => {
        throw error; // Propagate error
      });
  };

  const getRes = async (token) => {
    if (!!token) {
      const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studylist.php`;
      var config = {
        method: "post",
        url: session_url,
        data: {
          sid: token,
          pn: patientName,
          pi: patientId,
          on: ownerName,
          mn: modality,
          fd: fromDate,
          td: toDate,
          pages: limit,
          offset: offset,
        },
      };
      axios.defaults.withCredentials = true;
      const res = await axios(config);
      setStudyData(res.data);
      setIsLoading(false);
      setNextDisabled(res.data.length < limit);
      if ((res.data.length === 0) & (offset !== 0)) {
        setOffset(offset - limit);
      }
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Box
        component="form"
        sx={{
          display: "flex",
          justifyContent: "space-around",

          marginBottom: 2,
        }}
        noValidate
        autoComplete="off"
      >
        <Tooltip title="Press Enter to search" placement="right" arrow>
          <TextField
            id="patient-id"
            label="Patient ID"
            variant="standard"
            onChange={(e) => setPatientId(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                getRes(props.token);
              }
            }}
            onBlur={() => getRes(props.token)}
          />
        </Tooltip>
        <Tooltip title="Press Enter to search" placement="right" arrow>
          <TextField
            id="patient-name"
            label="Patient Name"
            variant="standard"
            onChange={(e) => setPatientName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                getRes(props.token);
              }
            }}
            onBlur={() => getRes(props.token)}
          />
        </Tooltip>
        <TextField
          id="standard-basic"
          label="Modality"
          variant="standard"
          select
          onChange={(e) => setModality(e.target.value)}
          value={modality}
          sx={{ width: "22ch" }}
        >
          <MenuItem value="">All</MenuItem>
          {modalities.map((modalityName, index) => (
            <MenuItem key={index} value={modalityName.code}>
              {modalityName.code}
            </MenuItem>
          ))}
        </TextField>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            inputFormat="DD-MM-YYYY"
            label="From"
            value={fromDate}
            onChange={(newFromValue) => {
              const fromDateObject = new Date(newFromValue);
              if (fromDateObject instanceof Date && !isNaN(fromDateObject)) {
                const fromDateOnly = new Date(
                  fromDateObject.getTime() -
                    fromDateObject.getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .split("T")[0];
                setFromDate(fromDateOnly);
              }
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                error={false}
                sx={{
                  svg: { color: "#B5B9C1" },

                  width: "10%",
                }}
              />
            )}
          />
          <DatePicker
            inputFormat="DD-MM-YYYY"
            label="Till"
            value={toDate}
            onChange={(newToValue) => {
              const toDateObject = new Date(newToValue);
              if (toDateObject instanceof Date && !isNaN(toDateObject)) {
                const toDateOnly = new Date(
                  toDateObject.getTime() -
                    toDateObject.getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .split("T")[0];
                setToDate(toDateOnly);
              }
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                error={false}
                sx={{ svg: { color: "#B5B9C1" }, width: "10%" }}
              />
            )}
          />
        </LocalizationProvider>
        {/* <Button
          sx={{ textTransform: "none", fontSize: "14px" }}
          endIcon={<SearchIcon />}
        >
          Search
        </Button> */}
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Study Table">
          <TableHead sx={{ fontWeight: "bold" }}>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>Patient ID</TableCell>
              <TableCell>Patient Name</TableCell>
              <TableCell>Modality</TableCell>
              <TableCell>Institution</TableCell>
              <TableCell>Study Date</TableCell>
              <TableCell>Study Description</TableCell>
              <TableCell>Accession No#</TableCell>
              <TableCell>Series</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studyData.length > 0 &&
              studyData?.map((row) => (
                <Row
                  key={row.ID}
                  row={row}
                  func={() => getRes(props.token)}
                  token={props.token}
                  open={row.ID == openRow}
                  onClick={(name) => setOpenRow(name)}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Select
          value={limit}
          onChange={handleChangeRows}
          variant="standard"
          disableUnderline
          sx={{
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
        <IconButton
          onClick={previousPage}
          disabled={offset <= 0}
          sx={{
            "&.MuiIconButton-root": {
              color: "#B5B9C1",
            },
            "&.Mui-disabled": {
              color: "#0000008a",
            },
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          sx={{
            "&.MuiIconButton-root": {
              color: "#B5B9C1",
            },
            "&.Mui-disabled": {
              color: "#0000008a",
            },
          }}
          onClick={nextPage}
          disabled={nextDisabled}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    </div>
  );
}
